export default {
  translation: {
    'Checkboxes.Diseases.Title': 'Оздоровление',
    'Checkboxes.Diseases.EmptyMessage': 'Такого заболевания не найдено, выберите пункт из списка',
    'Checkboxes.Diseases.Placeholder': 'Введите заболевание',
    'Checkboxes.Diseases.MoreButtonText': 'Все категории заболеваний',
    'Checkboxes.HotelTypes.Title': 'Объект размещения',
    'Checkboxes.MealOptions.Title': 'Питание',
    'Checkboxes.Amenities.Title': 'Удобства',
    'Checkboxes.MealOptions.MoreButtonText': 'Все типы питания',
    'Checkboxes.TreatmentFactors.Title': 'Лечебные факторы',
    'Checkboxes.LocationTypes.Title': 'Расположение',
    'Checkboxes.AccommodationFeatures.Title': 'Особенности размещения',
    'Price.Range': '{from} ₽ - {to} ₽',
    'Cashback.Popup':
      'Туристам - кешбэк, агенту - комиссию! Агент может оплатить по безналичному расчёту, или предложить туристам оплатить картой МИР и получить кешбэк. Для этого после оформления брони пришлите номер заказа на <a href="mailto:agent@sanatory.ru">agent@sanatory.ru</a>, и мы вышлем ссылку на оплату и документы для оформления комиссии агента.',
    'Common.Day': '{count, plural, one {{count} день} few {{count} дня} many {{count} дней}}',
    'Common.Reviews': '{count, plural, one {{count} отзыв} few {{count} отзыва} many {{count} отзывов}}',
    'Common.Adults': 'Взрослых',
    'Common.Commission': 'Комиссия {value}%',
    'Common.AgentCommission': 'Комиссия агента',
    'Common.Place': `{count, plural,
      one {место}
      few {места}
      many {мест}
    }`,
    'Common.Duration': 'Продолжительность {duration}',
    'Manual.Button': 'PDF инструкция',
    'Manual.Button.Text': 'Инструкция',
    'Common.From': 'От',
    'Common.Dates.Invalid': 'Выберите дату',
    'Common.Clear': 'Очистить',
    'Common.Apply': 'Применить',
    'Common.Cashback': 'кэшбэк',
    'Common.To': 'До',
    'Common.Budget': 'Бюджет',
    'Common.RoomType': 'Номер',
    'Common.Tariff': 'Тариф',
    'Common.Childs': 'Детей',
    'Common.ShowRooms': 'Показать номера',
    'Common.HideList': 'Скрыть список',
    'Common.SelectChildAge': 'Укажите возраст детей',
    'Common.Distance.Km': 'км',
    'Common.Distance.M': 'м',
    'Common.Distance.Min': 'мин',
    'Common.Close': 'Закрыть',
    'Common.ShowMore': 'Показать еще',
    'Common.Distances.More': 'Посмотреть как доброться',
    'Filters.Apply': 'Примененные фильтры',
    'Filters.Clear': 'Сбросить все фильтры',
    'Filters.Disease.Placeholder': 'Введите заболевание',
    'Filters.Disease.Placeholder.Full': 'Введите профиль лечения',
    'Auth.LogOut': 'Выйти',
    'Auth.ResetPassword.Title': 'Придумайте пароль',
    'Auth.ResetPassword.Actions.Submit': 'Установить пароль',
    'Auth.ResetPassword.Actions.Reset': 'Отмена',
    'Auth.ForgotPassword.Title': 'Сбросьте пароль',
    'Auth.ForgotPassword.Description': `
      Введите адрес электронной почты, связанный с вашим аккаунтом, и мы
      вышлем вам ссылку для изменения пароля.
    `,
    'Auth.ForgotPassword.Actions.Submit': 'Сбросить пароль',
    'Auth.ForgotPassword.Actions.Reset': 'Отмена',
    'Auth.ForgotPassword.Actions.Repeat': 'Ввести другой email',
    'Auth.ForgotPassword.Actions.SignIn': 'Вернуться на страницу входа',
    'Auth.ForgotPassword.Messages.Success': 'Информация по сбросу пароля отправлена на почту {email}',
    'Auth.ForgotPassword.Messages.Error': 'Пользователь с email {email} не зарегистрирован в системе',
    'Auth.FormFields.Email.Label': 'E-mail',
    'Auth.FormFields.Email.Placeholder': 'Ваш электронный адрес',
    'Auth.FormFields.Password.Label': 'Пароль',
    'Auth.FormFields.Password.Placeholder': 'Введите пароль',
    'Auth.FormFields.PasswordConfirmation.Label': 'Подтверждение пароля',
    'Auth.FormFields.PasswordConfirmation.Placeholder': 'Введите пароль еще раз',
    'Auth.Register.Manager.FIO.Label': 'Фио',
    'Auth.Register.Manager.FIO.Placeholder': 'Введите полное имя',
    'Auth.Register.Manager.Position.Label': 'Должность',
    'Auth.Register.Manager.Position.Placeholder': 'Например, агент',
    'Auth.Register.Manager.Email.Placeholder': 'сompany@company.com',
    'Auth.Register.Manager.Email.Placeholder2': 'user@company.com',
    'Auth.Register.Manager.Phone.Label': 'Телефон',
    'Auth.Register.Manager.Phone.Placeholder': '+7',
    'Auth.Register.LegalEntity.Email.Label': 'E-mail основной',
    'Auth.Register.LegalEntity.Email.Label2': 'E-mail для уведомлений',
    'Auth.Register.LegalEntity.Accounting.Label': 'ФИО бухгалтера',
    'Auth.Register.LegalEntity.Accounting.Label2': 'E-mail бухгалтера',
    'Auth.Register.LegalEntity.Accounting.Label3': 'Телефон бухгалтера',
    'Auth.Register.LegalEntity.Requisites.Label': 'Название банка',
    'Auth.Register.LegalEntity.Requisites.Label2': 'БИК',
    'Auth.Register.LegalEntity.Requisites.Label3': 'КПП',
    'Auth.Register.LegalEntity.Requisites.Label4': 'Расчетный счет',
    'Auth.Register.LegalEntity.Requisites.Label5': 'Корреспондентский счет',
    'Auth.Register.LegalEntity.Requisites.Placeholder': 'Введите название',
    'Auth.Register.LegalEntity.Requisites.Placeholder2': 'Номер',
    'Auth.Register.Addresses.Label': 'Город',
    'Auth.Register.Addresses.Label2': 'Улица',
    'Auth.Register.Addresses.Label3': 'Дом, стр.',
    'Auth.Register.Addresses.Label4': 'Офис',
    'Auth.Register.Addresses.Label5': 'Индекс',
    'Auth.Register.Addresses.Label6': 'Страна',
    'Auth.Register.Addresses.Placeholder': 'XXXXXX',
    'Auth.Register.AboutCompany.Label': 'ИНН',
    'Auth.Register.AboutCompany.Label2': 'Краткое название',
    'Auth.Register.AboutCompany.Label3': 'Полное название',
    'Auth.Register.AboutCompany.Label4': 'Юр. лицо',
    'Auth.Register.AboutCompany.Label5': 'Налогообложение',
    'Auth.Register.AboutCompany.Label6': 'Сеть агентств',
    'Auth.Register.AboutCompany.Label7': 'ФИО руководителя',
    'Auth.Register.AboutCompany.Label8': 'Должность',
    'Auth.Register.AboutCompany.Label9': 'Сеть агентств',
    'Auth.Register.AboutCompany.Placeholder': 'Введите номер',
    'Auth.Register.AboutCompany.Placeholder2': 'Название',
    'Auth.Register.AboutCompany.Placeholder3': 'Например, директор',
    'Auth.Register.AboutCompany.Placeholder4': 'Выберите',
    'Adult.Count': '{count, plural, =0 {без взрослых} one {# взрослый} few {# взрослых} many {# взрослых}}',
    'Child.Count': '{count, plural, =0 {без детей} one {# ребёнок} few {# ребёнка} many {# детей}}',
    'Common.Guests': '{count, plural, one {# гость} few {# гостя} many {# гостей}}',
    'Filters.Places.Placeholder': 'Введите город',
    'Filters.Places.Placeholder.Full': 'Введите город, регион или название санатория / отеля',
    'Filter.Places.RecentQueries.Title': 'Недавние поисковые запросы',
    'Filter.Places.PopularQueries.Title': 'Популярные запросы',
    'Filters.Places.Hotels.Title': 'Санатории и отели',
    'Filters.Places.Regions.Title': 'Похоже, вы ищете',
    'Finances.Title': 'Финансы',
    'Finances.Credit': 'Кредит',
    'Finances.Debit': 'Долг',
    'Finances.Balance.Liability': 'Лимит новых заказов',
    'Finances.Balance.Deposit': 'Депозит',
    'Finances.Balance.CreditLimit': 'Кредитный лимит',
    'Finances.Balance.Asset': 'Переплата',
    'Finances.Balance.PotentialDebit': 'Сумма неоплаченных заказов',
    'Finances.Balance.RealDebit': 'Счета ожидающие оплату',
    'Finances.Balance.OverdueDebit': 'Просроченная задолжность',
    'Contract.Label': 'Договор',
    'Contract.Placeholder': 'Договор',
    'Contract.Empty': 'Нет договора',
    'Contract.Value': 'Договор {number}',
    'Profile.Title': 'Мой профиль',
    'Profile.General.Title': 'Общее',
    'Profile.Personal.Title': 'Личные данные',
    'Profile.Email.Label': 'Почта',
    'Profile.Email.Placeholder': 'Введите почту',
    'Profile.FullName.Label': 'Имя пользователя',
    'Profile.FullName.Placeholder': 'Имя пользователя',
    'Profile.FullNameAcronym.Label': 'ФИО',
    'Profile.FullNameAcronym.Placeholder': 'ФИО',
    'Profile.FirstName.Label': 'Имя',
    'Profile.FirstName.Placeholder': 'Имя',
    'Profile.MiddleName.Label': 'Отчество',
    'Profile.MiddleName.Placeholder': 'Отчество',
    'Profile.LastName.Label': 'Фамилия',
    'Profile.LastName.Placeholder': 'Фамилия',
    'Profile.Phone.Label': 'Телефон',
    'Profile.Phone.Placeholder': 'Телефон',
    'Profile.ExtraPhone.Label': 'доб. телефон',
    'Profile.ExtraPhone.Placeholder': 'доб. телефон',
    'Profile.1CLogin.Label': 'Логин в 1С',
    'Profile.1CLogin.Placeholder': 'Логин в 1С',
    'Profile.UplaodPhoto.New': 'Новое фото',
    'Profile.UplaodPhoto.Remove': 'Удалить фото',
    'Profile.Department': 'Филиал',
    'Profile.Rules': 'Права',
    'Traveller.FullName': '{last_name} {first_name} {middle_name}',
    'Form.Age.Label': 'возраст',
    'Form.Age.Value': `{age} {age, plural,
      one {год}
      few {лет}
      many {лет}
    }`,
    'Profile.Role.Label': 'Роль',
    'Profile.Role.Value': `{value, select,
      admin {Администратор}
      manager {Менеджер}
    }`,
    'Form.Gender.Label': 'пол',
    'Form.Gender.Male': 'мужской',
    'Form.Gender.Female': 'женский',
    'Form.Gender.Value': `{gender, select,
      male {мужской}
      female {женский}
      other {не определен}
    }`,
    'Form.GenderAndAge.Value': `{gender, select,
      male {{age, select,
        0 {младенец}
        1 {мальчик, {age} год}
        2 {мальчик, {age} года}
        3 {мальчик, {age} года}
        4 {мальчик, {age} года}
        5 {мальчик, {age} лет}
        6 {мальчик, {age} лет}
        7 {мальчик, {age} лет}
        8 {мальчик, {age} лет}
        9 {мальчик, {age} лет}
        10 {мальчик, {age} лет}
        11 {мальчик, {age} лет}
        12 {мальчик, {age} лет}
        13 {мальчик, {age} лет}
        14 {мальчик, {age} лет}
        15 {мальчик, {age} лет}
        16 {мальчик, {age} лет}
        17 {мальчик, {age} лет}
        18 {мальчик, {age} лет}
        other {мужчина, {age} лет}
      }}
      female {{age, select,
        0 {младенец}
        1 {девочка, {age} год}
        2 {девочка, {age} года}
        3 {девочка, {age} года}
        4 {девочка, {age} года}
        5 {девочка, {age} лет}
        6 {девочка, {age} лет}
        7 {девочка, {age} лет}
        8 {девочка, {age} лет}
        9 {девочка, {age} лет}
        10 {девочка, {age} лет}
        11 {девочка, {age} лет}
        12 {девочка, {age} лет}
        13 {девочка, {age} лет}
        14 {девочка, {age} лет}
        15 {девочка, {age} лет}
        16 {девочка, {age} лет}
        17 {девочка, {age} лет}
        18 {девочка, {age} лет}
        other {женщина, {age} лет}
      }}
      other {не определен}
    }`,
    'Form.FirstName.Label': 'Имя',
    'Form.LastName.Label': 'Фамилия',
    'Form.MiddleName.Label': 'Отчество',
    'Form.BirthDate': 'Дата рождения',
    'Form.FullName.Label': 'Фамилия Имя Отчество',
    'Form.AddressSearch': 'Поиск по адресу',
    'Form.HotelSearch': 'Поиск по названию санатория',
    'Form.RoomsCounter.Label': 'Таких номеров',
    'Hotel.RoomType.Form.Description':
      'Онлайн-бронирование подтверждается сразу в санатории/отеле. Нажимая кнопку «Забронировать» вы подтверждаете, что соглашаетесь с ценой, условиями тарифа и правилами отмены бронирования, а также подтверждаете согласие туристов на обработку и передачу персональных данных, необходимых для бронирования    ',
    'Hotel.RoomTypes.Amenities.ShowButton': 'Все удобства ({count})',
    'Hotel.RoomTypes.Services.ShowButton': 'Все услуги ({count})',
    'Hotel.RoomTypes.Modal.Amenities.Common': 'Общие',
    'Hotel.RoomTypes.Modal.Amenities.Title': 'Удобства и услуги',
    'Hotel.RoomTypes.Modal.Services.Title': 'Услуги в тарифе',
    'Hotel.RoomTypes.RoomArea': '{count} кв. м.',
    'Hotel.RoomTypes.RoomCount': '{count, plural, =0 {нет комнат} one {# комната} few {# комнаты} many {# комнат}}',
    'Hotel.RoomTypes.GuestsMax':
      '{count, plural, =0 {Без гостей} one {до # гостя} few {до # гостей} many {до # гостей}}',
    'Hotel.NotAvailable': 'К сожалению, на все даты нет доступных номеров.',
    'Hotel.ChooseOther': 'Выбрать другой санаторий',
    'Hotel.AgeRestrictions': 'Возрастные ограничения',
    'Hotel.AgeRestrictionsFrom': `{count, plural,
      =0 {без ограничений}
      one {дети от {count} года}
      few {дети от {count} лет}
      many {дети от {count} лет}
    }`,
    'Hotel.RoomType.PriceCalendar.Title': 'Календарь цен',
    'Hotel.RoomType.Guests.Title': 'Размещение гостей',
    'Hotel.RoomType.Guests.ExtraBedsHit': `+ {count, plural,
      one {{count} доп. место}
      few {{count} доп. места}
      many {{count} доп. мест}
    }`,
    'Hotel.RoomType.PriceCalendar.Title.Description': 'На календаре указана стоимость в сутки за всех выбранных гостей',
    'Hotel.RoomTypes.Tariff.Quantity':
      '{count, plural, one {# процедура в день} few {# процедуры в день} many {# процедур в день}}',
    'Hotel.RoomTypes.Tariff.Quantity.Duration': '{duration} процедур в день',
    'Hotel.CheckIn': 'Заезд',
    'Hotel.CheckInTime': 'после {time}',
    'Hotel.CheckOut': 'Выезд',
    'Hotel.CheckOutTime': 'до {time}',
    'Hotel.EstimatedTime': 'Расчетный час',
    'Hotel.Taxes': 'Курортный сбор',
    'Hotel.ChildrenFrom': 'Проживание детям от {children_from} лет',
    'Hotel.TreatmentFactors': 'Лечебные факторы: ',
    'Hotel.Restrictions': 'Ограничения',
    'Hotel.Attention.Title': '<a>Документы</a>, которые вам потребуются для поездки',
    'Hotel.Programs.Description':
      "Ознакомьтесь с программами лечения, в случае необходимости мы <span class='green'>бесплатно</span> проконсультируем по любой программе.",
    'Hotel.TreatmentPrograms.IncludedText': 'Что входит в программу',
    'Hotel.Details.Tabs.Rules': 'Правила',
    'Hotel.Details.Tabs.Programs': 'Программы',
    'Hotel.Details.Tabs.Programs.Treat': 'Программы лечения',
    'Hotel.Details.Tabs.TreatmentPrograms': 'Профили лечения',
    'Hotel.Details.Tabs.Procedures': 'Процедуры',
    'Hotel.Details.Tabs.Meal': 'Питание',
    'Hotel.Details.Tabs.Infrastructure': 'Инфраструктура',
    'Hotel.Details.Tabs.Description': 'Описание',
    'Hotel.Details.Tabs.Map': 'На карте',
    'Hotel.Details.Tabs.Comments': 'Отзывы',
    'Hotel.Details.Specializations.Caption': 'Главные показания к лечению в санатории:',
    'Hotel.Details.Map': 'На карте',
    'Hotel.Details.CloseMap': 'Закрыть карту',
    'Hotel.Map.HowToGet': 'Как добраться',
    'Hotel.Distances.FromAirport': 'От аэропорта',
    'Hotel.Distances.FromRailwayStation': 'От железно-дорожной станции',
    'Hotel.Distances.FromBusStop': 'От автобусной остановки',
    'Hotel.PriceFrom': 'от',
    'Hotel.Search.Sort': 'сортировать',
    'Hotel.Search.Rating': 'рейтинг',
    'Hotel.Search.Rating.Title': 'По рейтингу',
    'Hotel.Search.Rating.Item': 'Рейтинг выше {from} из {to}',
    'Hotel.Search.PriceAsc': 'Цена по возрастанию',
    'Hotel.Search.PriceDesc': 'Цена по убыванию',
    'Hotel.One.CheckIn': 'Заезд с {time}',
    'Hotel.One.CheckOut': 'Выезд до {time}',
    'Hotel.One.More': 'Подробное описание',
    'Tariff.FeedType': 'Питание',
    'Order.OrderForm.Attention1': '<b>Удобно:</b> можно не указывать город и найти все санатории по профилю лечения',
    'Order.OrderForm.Attention2':
      '<b>Важно:</b> укажите количество туристов в 1 номере. Купить сразу несколько номеров и добавить больше туристов можно будет на странице санатория.',
    'Order.Empty': 'В заказе не выбрано ни одного номера. Добавьте номер или удалите пустой заказ.',
    'Order.Calendar.Tooltip.ClosedArrival': 'Выезд на дату запрещен',
    'Order.Calendar.Tooltip.ClosedArrival.Enter': 'Заезд на дату запрещен',
    'Order.Calendar.Tooltip.ClosedDepartureArrival': 'Заезд и выезд на дату запрещен',
    'Tariff.Package.Attention':
      'Программа расчитана на {unit, select, night {{value, plural, one {# ночь} few {# ночи} many {# ночей}}} day {{value, plural, one {# день} few {# дня} many {# дней}}}}. Выбор иного периода невозможен.',
    'Tariff.Restrictions.ClosedArrival': 'На выбранную дату заезда тариф недоступен',
    'Tariff.Restrictions.ClosedDeparture': 'На выбранную дату выезда тариф недоступен',
    'Tariff.Restrictions.MinDaysBeforeCheckIn':
      'Минимальное количество {unit, select, night {ночей} day {дней}} до заезда – {unit, select, night {{value, plural, one {# ночь} few {# ночи} many {# ночей}}} day {{value, plural, one {# день} few {# дня} many {# дней}}}}',
    'Tariff.Restrictions.MaxDaysBeforeCheckIn':
      'Максимальное количество {unit, select, night {ночей} day {дней}} до заезда – {unit, select, night {{value, plural, one {# ночь} few {# ночи} many {# ночей}}} day {{value, plural, one {# день} few {# дня} many {# дней}}}}',
    'Tariff.Restrictions.MaxLengthOfStay': `Максимальный срок пребывания – {billing_hour, select,
      day {{count, plural,
        one {# день}
        few {# дня}
        many {# дней}
      }}
      night {{count, plural,
        one {# ночь}
        few {# ночи}
        many {# ночей}
      }}
    }`,
    'Tariff.Restrictions.MinLengthOfStay': `Минимальный срок пребывания – {billing_hour, select,
      day {{count, plural,
        one {# день}
        few {# дня}
        many {# дней}
      }}
      night {{count, plural,
        one {# ночь}
        few {# ночи}
        many {# ночей}
      }}
    }`,
    'Tariff.Restrictions.MaxLengthOfStayArrival': `Максимальный срок пребывания, начиная с выбранной даты – {billing_hour, select,
      day {{count, plural,
        one {# день}
        few {# дня}
        many {# дней}
      }}
      night {{count, plural,
        one {# ночь}
        few {# ночи}
        many {# ночей}
      }}
    }`,
    'Tariff.Restrictions.MinLengthOfStayArival': `Минимальный срок пребывания, начиная с выбранной даты – {billing_hour, select,
      day {{count, plural,
        one {# день}
        few {# дня}
        many {# дней}
      }}
      night {{count, plural,
        one {# ночь}
        few {# ночи}
        many {# ночей}
      }}
    }`,
    'Order.PriceBlock.Restrictions.Title': 'Ограничения по тарифу',
    'Order.PriceBlock.Restrictions.Header': 'Невозможно выбрать эти даты',
    'Order.PriceBlock.ActiveRestrictions.Title': 'Ограничения на выбранную дату заезда',
    'Order.Calendar.Tooltip.CloseDeparture': 'Запрещен заезд на дату',
    'Order.Calendar.Tooltip.LengthOfStay': `Выберите период:{min, plural,
      =0 { }
      one {<br />min – # день}
      few {<br />min – # дня}
      many {<br />min – # дней}
    }{max, plural,
      =0 { }
      one {<br />max – # день}
      few {<br />max – # дня}
      many {<br />max – # дней}
    }`,
    'Order.Calendar.Tooltip.StopSales': 'По выбранному тарифу<br />нет доступных номеров<br />на эти даты',
    'Order.Calendar.Tooltip.AvailableBeds': `По выбранному тарифу<br />{count, plural,
      one {доступен {count} номер}
      few {доступно {count} номера}
      many {доступно {count} номеров}
    }`,
    'Order.Calendar.Tooltip.OneFreeBed': 'По выбранному тарифу<br />доступно 1 место<br />(подселение)',
    'Order.Calendar.Tooltip.RoomsFreeBeds': `По выбранному тарифу<br/>{count, plural,
      one {доступен {count} номер}
      few {доступно {count} номера}
      many {доступно {count} номеров}
    }<br />целиком и {countBeds} {countBeds, plural,
      one {место}
      few {места}
      many {мест}
    } в<br/>другом номере<br/>(подселение)`,
    'Order.SyncWithOneC': 'Синхронизировать с 1С',
    'Order.UploadXLS': 'Выгрузить XLS',
    'Order.DownloadVoucher': 'Скачать ваучер',
    'Order.DownloadBill': 'Скачать счет',
    'Order.Voucher': 'Ваучер',
    'Order.Bill': 'Счет',
    'Order.Number': '№{order_code}',
    'Order.PaymentInfo.Title': 'Информация об оплате',
    'Order.PaymentInfo.Revenue': 'Комиссия',
    'Order.PaymentInfo.PayTill': 'Оплатить до',
    'Order.PaymentInfo.InitialCost': 'Начальная стоимость',
    'Order.PaymentInfo.TotalCost': 'Итоговая стоимость',
    'Order.PaymentInfo.NetPrice': 'Сумма к оплате (агентом)',
    'Order.PaymentInfo.GrossPrice': 'Цена туристам, включая комиссию агента',
    'Order.PaymentInfo.NetPriceShort': 'Сумма со скидкой',
    'Order.PaymentInfo.Discount': 'Комиссия / Скидка агента',
    'Order.PaymentInfo.ExtraCharge': 'Доп. прибыль агента',
    'Order.PaymentInfo.Fee': 'Комиссия',
    'Order.PaymentInfo.FreeCancel': 'Бесплатная отмена до',
    'Order.PaymentMethod.Label': 'Тип оплаты',
    'Order.PaymentMethod.Value': `{type, select,
      bank_transfer {Безналичный по договору}
      cash {Наличными}
    }`,
    'Order.UserOrder.Title': 'Мой заказ',
    'Order.Confirm': 'Забронировать',
    'Order.Next': 'Далее',
    'Order.Cancel': 'Отменить заказ',
    'Order.Title': 'Заказ',
    'Order.AddTraveler': 'Добавить гостя',
    'Order.AddRoom': 'Добавить номер',
    'Order.History': 'История заказа',
    'Order.RoomType.DropdownAction.Closed': 'Есть тарифы на другие даты',
    'Order.RoomType.DropdownAction.Opened': 'Скрыть недоступные тарифы',
    'Order.RoomType.Count.1': 'Одноместный номер',
    'Order.RoomType.Count.2': 'Двухместный номер',
    'Order.RoomType.Count.3': 'Трёхместный номер',
    'Order.RoomType.Count.4': 'Четырёхместный номер',
    'Order.RoomType.Action.More': 'О номере и условиях аннуляции',
    'Order.Remove.Caption': 'Вы уверены, что хотите отменить заказ?',
    'Order.Remove.Accept': 'Отменить заказ',
    'Order.Remove.Cancel': 'Отмена',
    'Order.Reservation.Remove.Caption': 'Вы уверены, что хотите отменить бронирование?',
    'Order.Reservation.Remove.Accept': 'Да, отменить',
    'Order.Reservation.Remove.Cancel': 'Не отменять',
    'Order.Reservation.AddRoom': 'Дополнительный номер',
    'Order.Reservation.FreeSeats': `{count} {count, plural,
      one {место}
      few {места}
      many {мест}
    } сводобно`,
    'Order.Agreement': `
      <h4>Важно!</h4>
      <p><b>Редактирование заказа или номера в заказе:</b></p>

      <p>При добавлении номера в заказ внимательно ознакомьтесь с правилами тарифа и условиями отмены.
      В рамках одного заказа можно добавлять разные категории номеров и типы тарифов.
      При редактировании дат проживания, типа тарифа забронированного номера - будет осуществлен пересчёт стоимости согласно тарифам, действующим на момент редактирования.
      Изменения затрагивают только редактируемый номер и не влияют на другие ранее подтвержденные номера в заказе.
      <br />При необходимости изменить ФИО без изменения цены – просим вас обратиться в службу поддержки <a href='mailto:reservation@sanatory.ru'>reservation@sanatory.ru</a>. Данная услуга предоставляется по запросу, при положительном решении со стороны объекта размещения.</p>

      <p><b>Отмена заказа или номера в заказе:</b></p>

      <p>При отмене номера действуют условия аннуляции и возможные штрафные санкции согласно правилам тарифа.
      Если в заказе забронировано несколько номеров по различным тарифам, при отмене такого заказа целиком действуют наиболее строгие условия аннуляции и штрафные санкции.</p>
    `,
    'Order.Travellers.OrderNumber': 'Заказ {number}',
    'Order.Travellers.AddTraveler': 'Добавить гостя',
    'Order.Travellers.ChooseRoom': 'Выбор номера',
    'Order.Travellers.NewTravellers': `Данные {count, plural,
      one {нового гостя}
      few {новых гостей}
      many {новых гостей}
    }`,
    'Order.Travellers.RoomType': `Выберите номер для {count, plural,
      one {гостя}
      few {гостей}
      many {гостей}
    }`,
    'Order.RoomDescription.Title': 'Описание номера и тарифа',
    'Order.Traveller.NoTraveller': 'Нет',
    'Order.OrderInfo.DatesRange': 'Заезд – выезд',
    'Order.OrderInfo.Contract': 'Договор',
    'Order.OrderInfo.Insurance': 'По страховке',
    'Order.OrderInfo.Cash': 'За наличные',
    'Order.OrderInfo.Reservations.Title': 'Номера в заказе',
    'Order.OrderInfo.Amount': 'Стоимость',
    'Order.OrderInfo.CashSurcharge': 'Доплата наличными',
    'Hotel.One.FreeCancel': 'Бесплатная отмена',
    'Orders.Title': 'Заказы',
    'Orders.Status': `{code, select,
      created {Новый}
      booked {Черновик}
      confirmed {Подтверждён}
      cancelled {Отменен}
      archived {Архивный}
    }`,
    'Order.Actions.NotAvailable': 'Любые изменения невозможны, потому что заказ был ранее отменен.',
    'Order.Actions.Archive': 'Архивировать',
    'Order.Groups.Views': `{view, select,
      tariff {По номерам}
      dates {По датам}
      reservation {Без группировки}
    }`,
    'Orders.SearchOrder': 'Поиск заказа',
    'Orders.NewOrder': 'Новый заказ',
    'Orders.Total': 'Итого: {price, number, RUB}',
    'Orders.Total.Sum': 'Итого: {price, number, RUB_SUM}',
    'Orders.Guests': `{count, plural,
      =0 {нет гостей}
      one {{count} гость}
      few {{count} гостя}
      many {{count} гостей}
    }`,
    'Orders.Rooms': `{count, plural,
      =0 {нет номеров}
      one {{count} номер}
      few {{count} номера}
      many {{count} номеров}
    }`,
    'Orders.TableHead.Code': 'ID Заказа',
    'Orders.TableHead.Dates': 'Даты',
    'Orders.TableHead.CheckIn': 'Заезд',
    'Orders.TableHead.CheckOut': 'Выезд',
    'Orders.TableHead.Travellers': 'Гости',
    'Orders.TableHead.Hotel': 'Санаторий',
    'Orders.TableHead.Manager': 'Менеджер',
    'Orders.TableHead.Changed': 'Изменен',
    'Orders.TableHead.State': 'Статус',
    'Orders.TableItem.OrderID': 'ID заказа',
    'Orders.TableItem.CreatedAt': 'Дата создания',
    'Orders.TableItem.PayTill': 'Оплатить до',
    'Orders.TableItem.PaymentType': 'Тип оплаты',
    'Orders.TableItem.Price': 'Начальная стоимость',
    'Orders.TableItem.Fee': 'Комиссия',
    'Orders.TableItem.TotalAmount': 'К оплате',
    'Orders.TableItem.FreeCancelation': 'Бесплатная отмена',
    'Orders.TableItem.RoomType': 'Тип номера',
    'Orders.TableItem.Tariff': 'Тариф',
    'Orders.TableItem.Seats': 'Число мест',
    'Orders.TableItem.Seats.Main': `{count} {count, plural,
      one {основное}
      few {основных}
      many {основных}
    }`,
    'Orders.TableItem.Seats.Extra': `{count} {count, plural,
      one {доп.}
      few {доп.}
      many {доп.}
    }`,
    'Orders.TableItem.Seats.Extra.FullText': `{count} {count, plural,
      one {дополнительное}
      few {дополнительных}
      many {дополнительных}
    }`,
    'Orders.TableItem.Description': 'в том числе за каждого гостя:',
    'Orders.TableItem.Seats.Main.Full': `{count} {count, plural,
      one {основное место}
      few {основных места}
      many {основных мест}
    }`,
    'Orders.TableItem.Seats.Extra.Full': `{count} {count, plural,
      one {доп. место}
      few {доп. места}
      many {доп. мест}
    }`,
    'Orders.Hotel.Badge.NetPrice': 'Итого к оплате',
    'Orders.Hotel.Badge.NetPrice.Tourist': 'Цена туристам',
    'Orders.Hotel.Badge.NetPrice.Agent': 'Итого к оплате (агентом)',
    'Orders.Hotel.NetPrice.Tourist': 'Цена туристам',
    'Orders.Hotel.NetPrice.Comission': 'Комиссия агента',
    'Orders.Hotel.Submit': 'Забронировать как агент',
    'Orders.Hotel.Badge.GrossPrice': 'Цена для туристов',
    'Orders.Hotel.Badge.Comission': 'Комиссия агента',
    'Orders.Hotel.Badge.Benefit': 'Выгода',
    'Orders.Hotel.Badge.Percent': '{value}% комиссия',
    'Orders.TableItem.AdultsMain': 'Взрос. на осн. месте',
    'Orders.TableItem.ChildMain': 'Дети на осн. месте',
    'Orders.TableItem.AdultsExtra': 'Взрос. на доп. месте',
    'Orders.TableItem.ChildExtra': 'Дети на доп. месте',
    'Orders.TableItem.Status.Created': 'Новый',
    'Orders.TableItem.Status.Booked': 'Забронирован',
    'Orders.TableItem.Status.Confirmed': 'Подтверждён',
    'Orders.TableItem.Status.Cancelled': 'Отменён',
    'Orders.TravellersList.Expand': `ещё {count} {count, plural,
      one {гость}
      few {гостя}
      many {гостей}
    }`,
    'Orders.Categories.All': 'Все',
    'Orders.Categories.Coming': 'Будущие',
    'Orders.Categories.Completed': 'Завершенные',
    'Orders.Categories.Canceled': 'Отмененные',
    'Orders.Categories.ComingCanceled': 'Скоро отмена',
    'Orders.Categories.Archive': 'Архив',
    'Orders.FilterButton': 'Фильтр',
    'Orders.Filters.CheckInDate.Label': 'Даты заезда',
    'Orders.Filters.CheckOutDate.Label': 'Даты выезда',
    'Orders.Filters.CheckRangeDate.Label': 'Заезд — выезд',
    'Orders.Filters.CheckRangeDate.Value': 'с {from} по {to}',
    'Orders.Filters.Travellers.Label': 'Имя гостя',
    'Orders.Filters.Hotels.Label': 'Санаторий',
    'Orders.Filters.Managers.Label': 'Менеджер',
    'Orders.Filters.OrderCode.Label': 'Номер заказа',
    'Orders.Filters.State.Label': 'Статус',
    'Orders.Filters.Clear': 'Очистить фильтры',
    'Orders.Filters.InstantBooking': 'Моментальное подтверждение брони',
    'Orders.Filters.ProviderOstrovok': 'Предложение партнера: Островок',
    'Orders.DownloadReportButton': 'Скачать отчет',
    'Orders.Hotels.FavoriteHotels': 'Избранные санатории',
    'Orders.OrderForm.SubmitButton': 'Найти',
    'Orders.OrderForm.Add.SubmitButton': 'Найти варианты',
    'Orders.OrderForm.WithTreatment': 'С лечением',
    'Orders.OrderForm.WithoutTreatment': 'Без лечения',
    'Orders.OrderForm.AddTraveler': 'Добавить гостя',
    'Orders.OrderForm.Error': 'Пожалуйста, проверьте корректность введённых данных',
    'Orders.OrderForm.AddGuest': 'Добавить гостя',
    'Orders.Show.OrderAndCaht.Seats': `{count} {count, plural,
      one {гость}
      few {гостя}
      many {гостей}
    }`,
    'Orders.Show.OrderAndCaht.Rooms': `{count} {count, plural,
      one {номер}
      few {номера}
      many {номеров}
    }`,
    'Orders.Reservations.Duration': `{value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'Orders.Reservations.ForDuration': `за {value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'Orders.Reservations.TotalForDuration': `Итого за {value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'Orders.EmptyOrders0': 'Здесь отображается таблица с заказами.',
    'Orders.EmptyOrders1': 'Чтобы создать первый заказ, нажмите «Новый заказ».',
    'Orders.Submit.OnChange': 'Сохранить изменения',
    'Orders.Submit.OnCreate': 'Добавить в заказ',
    'Orders.Notifications.BookedSuccess': 'Бронь успешно добавлена',
    'Orders.Notifications.BookedError': 'Бронь не добавлена',
    'Pages.Orders': 'Заказы',
    'Pages.Resale': 'Перепродажа',
    'Pages.Reports': 'Отчётность',
    'Pages.Statistics': 'Статистика',
    'Pages.Finances': 'Финансы',
    'Pages.Settings': 'Настройки',
    'Pages.Contracts': 'Договоры',
    'Contracts.Title': 'Договоры компании',
    'Contracts.Subtitle': 'Сформировать договор',
    'Contracts.Description':
      'Сформированный договор необходимо распечатать, поставить подпись / печать и направить нам в систему <br /> ЭДО или на адрес: г. Москва, Новорязанская улица, дом 18, с 21',
    'Reports.Title': 'Отчетность',
    'Reports.Empty': 'Отчётов пока не создано',
    'Reports.Invoices.Title': 'Неоплаченные счета',
    'Reports.Invoices.Total': 'Сумма счетов к оплате: {value}',
    'Reports.Revise.Title': 'Сверка',
    'Reports.PaymentHistory.Title': 'История платежей',
    'Reports.ClosingDocuments.Title': 'Закрывающие документы',
    'Reports.ActsOfResidence.Title': 'Акты проживания',
    'Reports.TableHead.TransactionType': 'Операция',
    'Reports.TableHead.TransactionId': 'ID операции',
    'Reports.TableHead.CreatedAt': 'Создан',
    'Reports.TableHead.PaymentType': 'Тип платежа',
    'Reports.TableHead.Amount': 'Сумма',
    'Reports.TableHead.AccountCode': 'Номер счёта',
    'Settings.Title': 'Настройки',
    'Settings.General.Title': 'Основные настройки',
    'Settings.General.Company.Title': 'Данные компании',
    'Settings.General.Comission.Title': 'Комиссия',
    'Settings.General.Comission.ExtraCharge.Title': 'Ваша наценка',
    'Settings.General.Comission.ExtraCharge.Description':
      'Процент на который увеличивается исходная цена для демонстрации клиенту или автоматического расчета.',
    'Settings.General.Comission.Autofill.Title': 'Автозаполнение',
    'Settings.General.Comission.Autofill.Description':
      'Позволяет настроить автоматическое заполнение цены перепродажи, которая будет попадать в закрывающие документы.',
    'Settings.General.Emails.Title': 'Настройка почтовых рассылок',
    'Settings.General.Emails.TransactionalMailing.Title': 'Транзакционные рассылки',
    'Settings.General.Emails.TransactionalMailing.Description':
      'Включает автоматическое формирование счёта и отправку его на email при создании бронирования.',
    'Settings.General.Emails.PartnerLogo.Title': 'Логотип партнера',
    'Settings.General.Emails.PartnerLogo.DisplaySiteLogo.Label': 'Показывать логотип на сайте.',
    'Settings.General.Emails.PartnerLogo.Description':
      'Ваш логотип будет интегрирован на сайт и во все письма клиенту (подтверждениях бронирования, инциденты и др.).',
    'Settings.Contacts.Title': 'Контактные данные',
    'Settings.Contacts.Add': 'Добавить e-mail',
    'Settings.Contacts.Base.Title': 'Основные способы связи',
    'Settings.Contacts.Base.Description':
      'E-mail адреса контракта, на которые будут приходить важные уведомления, и по которым с вами свяжутся в случае срочных вопросов',
    'Settings.Contacts.Finances.Title': 'Финансовые вопросы',
    'Settings.Contacts.Finances.Description':
      'E-mail бухгалтерии, для уведомления о вопросах оплаты и решения финансовых вопросов',
    'Settings.Contacts.Technical.Title': 'Технические вопросы',
    'Settings.Contacts.Technical.Description': 'E-mail ответственного за техническую сторону сотрудничества',
    'Settings.Contacts.Common.Title': 'Общая поддержка',
    'Settings.Contacts.Common.Description': 'E-mail специалиста технической поддержки для решения общих вопросов',
    'Settings.Contracts.Title': 'Договоры компании',
    'Settings.Contracts.Legal.Title': 'Юр. лица',
    'Settings.Contracts.Legal.Empty': 'Юридических лиц пока нет',
    'Settings.Contracts.Legal.AccountingCode': 'КПП',
    'Settings.Contracts.Legal.Bank': 'Банк',
    'Settings.Contracts.Legal.BankCode': 'БИК',
    'Settings.Contracts.Legal.CorrespondentAccount': 'Корр. счет',
    'Settings.Contracts.Legal.LegalAddress': 'Юр. адрес',
    'Settings.Contracts.Legal.PaymentAccount': 'Рассчетный счет',
    'Settings.Contracts.Legal.Position': 'Должность',
    'Settings.Contracts.Legal.RealAddress': 'Физ. адрес',
    'Settings.Contracts.Legal.RegNumber': 'ОГРН',
    'Settings.Contracts.Legal.Signatory': 'Подписант',
    'Settings.Contracts.Legal.TIN': 'ИНН',
    'Settings.Contracts.List.Title': 'Договоры',
    'Settings.Contracts.List.Empty': 'Договоров пока нет',
    'Settings.Contracts.List.SignedAt': 'Дата подписания',
    'Settings.Contracts.List.EndAt': 'Дата окончания действия',
    'Settings.Contracts.List.StartAt': 'Дата начала действия',
    'Settings.Contracts.List.ContractType': 'Тип договора',
    'Settings.Contracts.List.ContractFormat': 'Форма договора',
    'Settings.Users.Title': 'Пользователи',
    'Settings.Users.Add.Title': 'Новый пользователь',
    'Settings.Users.Edit.Title': 'Редактировать пользователя',
    'Settings.Users.AddedUsers.Title': 'Добавленные пользователи',
    'Settings.Users.Notifications.AddedSuccess': 'Пользователь добавлен',
    'Settings.Users.Notifications.AddedError': 'Пользователь не добавлен',
    'Statistics.Title': 'Статистика',
    'Tariff.Package.Duration.Label': 'Длительность курса',
    'Tariff.Feed.Quantity': `{count, plural,
      one {{count} раз в день}
      few {{count} раза в день}
      many {{count} раз в день}
    }`,
    'Tariff.Booking': 'Бронирование',
    'Tariff.Manipulations.Title': 'Медицинские услуги',
    'Tariff.Cancellation.Title': 'Условия отмены',
    'Tariff.Price': 'Цена',
    'Tariff.Commission': 'Комиссия',
    'Tariff.IsNotAvailable': 'Доступен на другие даты',
    'Tariffs.Restriction.MinStayPeriod': 'Min срок проживания - {unit, select, night {{value} нч.} day {{value} дн.}}',
    'Tariffs.Cancellation.FullRefund': 'Бесплатная отмена до {date}',
    'Tariffs.Cancellation.FullTitle': 'Условия отмены',
    'Tariffs.Cancellation.NonRefund': 'При отмене удерживается полная сумма бронирования в размере {price} ₽',
    'Tariffs.CancellationPolicy.Deadline': 'При отмене в срок с {check_in} удерживается сумма в размере {price} ₽',
    'Tariff.NetPrice': 'Цена без комиссии',
    'Tariff.GrossPrice': 'Цена',
    'Tariff.PriceFrom': 'от {price, number, RUB}',
    'Tariff.Seats.Primary': `{count} {count, plural,
      one {основное место}
      few {основных места}
      many {основных мест}
    }`,
    'Tariff.Seats.Secondary': `{count} {count, plural,
      one {доп. место}
      few {доп. места}
      many {доп. мест}
    }`,
    'Tariff.Rooms.Count': `{count} {count, plural,
      one {комната}
      few {комнаты}
      many {комнат}
    }`,
    'Tariff.Room.Area': '{area} м<sup>2</sup>',
    'Tariff.Room.MoreImages': 'ещё {count}',
    'Tariff.Features.Title': 'Особенности',
    'Tariff.Features.Sharing': 'Возможность подселения',
    'Traveler.Age': `{count} {count, plural,
      one {год}
      few {года}
      many {лет}
    }`,
    'Traveler.Age.Suffix': ` {count, plural,
      one {год}
      few {года}
      many {лет}
    }`,
    'Tariff.ReservationFromDays': `Бронирование от {value} {unit, select,
      day {{value, plural,
        one {дня}
        few {дней}
        many {дней}
      }}
      night {{value, plural,
        one {ночи}
        few {ночей}
        many {ночей}
      }}
    }`,
    'Tariffs.FromDays': `от {value} {unit, select,
      day {{value, plural,
        one {дня}
        few {дней}
        many {дней}
      }}
      night {{value, plural,
        one {ночи}
        few {ночей}
        many {ночей}
      }}
    }`,
    'Tariffs.CourseDuration': `Длительность курса {value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'Tariff.Services.Title': 'Услуги входящие в тариф',
    'HotelCard.Action': 'Выбрать номер',
    'UI.HotelCard.Price.Agent': 'тариф агента',
    'UI.HotelCard.Price.Tourist': 'цена туристам',
    'UI.Discount': '{value, number, RUB}',
    'UI.Discount.From': 'от {value, number, RUB}',
    'UI.Button.Add': 'Добавить',
    'UI.Button.Back': 'Назад',
    'UI.Button.Save': 'Сохранить',
    'UI.Button.Confirm': 'Подтвердить',
    'UI.Button.Edit': 'Редактировать',
    'UI.Button.More': 'Подробнее',
    'UI.Button.Archive': 'Архивировать',
    'UI.Button.Cancel': 'Отмена',
    'UI.Price': '{price, number, RUB}',
    'UI.Price.Sum': '{price, number, RUB_SUM}',
    'UI.PriceFrom': 'от {price, number, RUB}',
    'UI.Percent': '{value} %',
    'UI.Duration': `{value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'UI.Guests': `{count} {count, plural,
      one {гость}
      few {гостя}
      many {гостей}
    }`,
    'UI.Guests.ParentCase': `{count} {count, plural,
      one {гостя}
      few {гостей}
      many {гостей}
    }`,
    'UI.ForDayQuantity': `за {count} {count, plural,
      one {день}
      few {дня}
      many {дней}
    }`,
    'UI.PerWeekQuantity': `{count} {count, plural,
      one {раз}
      few {раза}
      many {раз}
    } в неделю`,
    'UI.Fetching': 'Загружается...',
    'UI.DemoLimitations': 'Данный функционал находится в разработке',
    'UI.PaymentBadge.Label': `{value, select,
      trigger {Бронь}
      void {Отмена}
      pay {Оплата}
      refund {Возврат}
    }`,
    'UI.InvoiceState.ShortText': `{value, select,
      triggered {Не оплачен}
      required {Ждет оплаты}
      expired {Время оплаты истекло}
      paid {Оплачен}
      voided {Аннулирован}
      refunded {Выполнен возврат}
      not_defined {}
    }`,
    'UI.InvoiceState.LongText': `{value, select,
      triggered {Заказ не оплачен}
      required {Заказ ждет оплаты}
      expired {Время оплаты истекло}
      paid {Заказ оплачен}
      voided {Заказ аннулирован}
      refunded {По заказу выполнен возврат}
      not_defined {}
    }`,
    'UI.ProviderState.Value': `{value, select,
      created {Черновик}
      booked {Черновик}
      confirmed {Подтвержден}
      cancelled {Отменен}
      archived {Архивный}
    }`,
    'User.Roles.Administrator': 'Администратор',
    'User.Roles.Manager': 'Менеджер',
    'User.Action.Change': 'Изменить',
    'User.Action.Block': 'Заблокировать',
    'User.Action.Unblock': 'Разблокировать',
    'User.Action.Remove': 'Удалить',
    'BookingForm.Messages.DatesUnavailable': 'Нельзя забронировать номер, так как выбраны недоступные даты!',
    'BookingForm.Messages.TravellersRestrictions': 'Количество выбранных мест больше чем гостей!',
    'BookingForm.Messages.WithoutRates': 'Нельзя забронировать номер, так на выбранные даты нет цен!',
    'BookingForm.Messages.StopSales':
      'Нельзя забронировать номер, так на выбранные даты по данному тарифу остановлены продажи!',
    'Balance.Value': 'Баланс: {value}',
    'Balance.AssetValue': 'Счет переплаты: {value}',
    'Invoice.Footer.PayFromAssetBalance': 'Оплатить из переплаты',
    'Invoice.Footer.OrderInfo': 'Информация о заказе',
    'Invoice.Position.Number': 'ID заказа',
    'Invoice.Position.CheckIn': 'Заезд',
    'Invoice.Position.CheckOut': 'Выезд',
    'Invoice.Position.Guests': 'Гости',
    'Invoice.Position.Hotel': 'Санаторий',
    'Invoice.List.Number': 'Номер счета',
    'Invoice.List.InvoicedAt': 'Создан',
    'Invoice.List.ExpiredAt': 'Оплата до',
    'Invoice.List.NetPrice': 'К оплате',
    'Invoice.List.Manager': 'Менеджер',
    'CommissionButton.Enable': 'Отображать вознаграждение агента в результатах выдачи объектов и их тарифов.',
    'CommissionButton.Disable': 'Скрывать вознаграждение агента в результатах выдачи объектов и их тарифов.',
    'Tax.Conditions.per_guest_per_day': 'в сутки за гостя (18+)',
    'Tax.Conditions.per_room_per_day': 'в сутки за номер',
    'Tax.Conditions.per_guest_for_period': 'на весь период за гостя (18+)',
    'Tax.Conditions.per_room_for_period': 'на весь период за номер',

    'Tax.Payments.cash': 'на месте',
    'Tax.Payments.card': 'банковской картой',
    'Tax.Payments.cash_or_card': 'на месте или банковской картой'
  }
};
