import styled from 'styled-components';
import { theme } from './icons.theme';

import { ReactComponent as AddSvg } from 'assets/icons/add.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/icons/arrow-left.svg';
import { ReactComponent as CalendarSvg } from 'assets/icons/calendar.svg';
import { ReactComponent as CheckSvg } from 'assets/icons/check.svg';
import { ReactComponent as CircleArrowSvg } from 'assets/icons/circle-arrow.svg';
import { ReactComponent as CloseSvg } from 'assets/icons/close.svg';
import { ReactComponent as CloseSmallSvg } from 'assets/icons/close-small.svg';
import { ReactComponent as CornerDownSvg } from 'assets/icons/corner-down.svg';
import { ReactComponent as DeleteSvg } from 'assets/icons/delete.svg';
import { ReactComponent as DocumentSvg } from 'assets/icons/document.svg';
import { ReactComponent as DotsSvg } from 'assets/icons/dots.svg';
import { ReactComponent as EditSvg } from 'assets/icons/edit.svg';
import { ReactComponent as ErrorSvg } from 'assets/icons/error.svg';
import { ReactComponent as GoArrowSvg } from 'assets/icons/go-arrow.svg';
import { ReactComponent as ImageErrorSvg } from 'assets/icons/image-error.svg';
import { ReactComponent as LocationSvg } from 'assets/icons/location.svg';
import { ReactComponent as LogoSvg } from 'assets/icons/logo.svg';
import { ReactComponent as MagnifySvg } from 'assets/icons/magnify.svg';
import { ReactComponent as MessageSvg } from 'assets/icons/message.svg';
import { ReactComponent as NoPhotoSvg } from 'assets/icons/no-photo.svg';
import { ReactComponent as PenSvg } from 'assets/icons/pen.svg';
import { ReactComponent as PlusSvg } from 'assets/icons/plus.svg';
import { ReactComponent as SearchSvg } from 'assets/icons/search.svg';
import { ReactComponent as SortSvg } from 'assets/icons/sort.svg';
import { ReactComponent as StarSvg } from 'assets/icons/star.svg';
import { ReactComponent as StarBigSvg } from 'assets/icons/star-big.svg';
import { ReactComponent as SuccessSvg } from 'assets/icons/success.svg';
import { ReactComponent as SuccessSmallSvg } from 'assets/icons/success-small.svg';
import { ReactComponent as TriangleSvg } from 'assets/icons/triangle.svg';
import { ReactComponent as WarnQuestionSvg } from 'assets/icons/warn-question.svg';
import { ReactComponent as NotAvailableSvg } from 'assets/icons/not_dates.svg';
import { ReactComponent as BlueCopyIconSvg } from 'assets/icons/blue_copy_icon.svg';
import { ReactComponent as GreyCopyIconSvg } from 'assets/icons/grey_copy_icon.svg';
import { ReactComponent as UserBlueSvg } from 'assets/icons/user_blue.svg';
import { ReactComponent as UserGreySvg } from 'assets/icons/user_grey.svg';
import { ReactComponent as CommissionVisibleSvg } from 'assets/icons/commission_visible.svg';
import { ReactComponent as CommissionHiddenSvg } from 'assets/icons/commission_hidden.svg';
import { ReactComponent as DropdownSvg } from 'assets/icons/dropdown.svg';
import { ReactComponent as BudgetSvg } from 'assets/icons/budget_icon.svg';
import { ReactComponent as HeartSvg } from 'assets/icons/heart.svg';
import { ReactComponent as StopSalesSvg } from 'assets/icons/stop_sales.svg';
import { ReactComponent as ProviderOstrovokSvg } from 'assets/icons/provider-ostrovok.svg';
import { ReactComponent as ProviderOstrovokV2Svg } from 'assets/icons/provider-ostrovok2.svg';

import { ReactComponent as AnimatorSvg } from 'assets/icons/amenities/animator.svg';
import { ReactComponent as FitobarSvg } from 'assets/icons/amenities/apple_fitobar.svg';
import { ReactComponent as AtmSvg } from 'assets/icons/amenities/atm.svg';
import { ReactComponent as BankCardSvg } from 'assets/icons/amenities/bank_card.svg';
import { ReactComponent as BarSvg } from 'assets/icons/amenities/bar.svg';
import { ReactComponent as BathHatSvg } from 'assets/icons/amenities/bath_hat.svg';
import { ReactComponent as WifiSvg } from 'assets/icons/amenities/wi-fi.svg';
import { ReactComponent as BeautySalonSvg } from 'assets/icons/amenities/beauty_salon.svg';
import { ReactComponent as ConferenceSvg } from 'assets/icons/amenities/conference.svg';
import { ReactComponent as HealtyCentrSvg } from 'assets/icons/amenities/healthy_centr.svg';
import { ReactComponent as NurseSvg } from 'assets/icons/amenities/nurse.svg';
import { ReactComponent as OxygenCocktailSvg } from 'assets/icons/amenities/oxygen_cocktail.svg';
import { ReactComponent as ReceptionSvg } from 'assets/icons/amenities/reception.svg';
import { ReactComponent as RestarauntSvg } from 'assets/icons/amenities/restaraunt.svg';
import { ReactComponent as SaunaSvg } from 'assets/icons/amenities/sauna.svg';
import { ReactComponent as SpaSvg } from 'assets/icons/amenities/spa.svg';
import { ReactComponent as TransferSvg } from 'assets/icons/amenities/transfer.svg';
import { ReactComponent as TreeParkSvg } from 'assets/icons/amenities/tree_park.svg';
import { ReactComponent as GymSvg } from 'assets/icons/amenities/gym.svg';
import { ReactComponent as BathroomSvg } from 'assets/icons/amenities/bathroom.svg';
import { ReactComponent as SafeSvg } from 'assets/icons/amenities/safe.svg';

import { ReactComponent as RoomSvg } from 'assets/icons/room.svg';
import { ReactComponent as UsersIconSvg } from 'assets/icons/users_icon.svg';

import { ReactComponent as BadgeChargeSvg } from 'assets/icons/badge_charge.svg';
import { ReactComponent as BadgeVoidSvg } from 'assets/icons/badge_void.svg';
import { ReactComponent as BadgePaySvg } from 'assets/icons/badge_pay.svg';
import { ReactComponent as BadgeRefundSvg } from 'assets/icons/badge_refund.svg';

import { ReactComponent as InvoiceExpiredSvg } from 'assets/icons/invoice_expired.svg';
import { ReactComponent as InvoicePaidSvg } from 'assets/icons/invoice_paid.svg';
import { ReactComponent as InvoiceRequiredSvg } from 'assets/icons/invoice_required.svg';
import { ReactComponent as InvoiceTriggeredSvg } from 'assets/icons/invoice_triggered.svg';

import { ReactComponent as UserBlockSvg } from 'assets/icons/user_block.svg';
import { ReactComponent as QuestionSvg } from 'assets/icons/question.svg';

import { ReactComponent as BedSvg } from 'assets/icons/bed.svg';
import { ReactComponent as AttentionSvg } from 'assets/icons/attention.svg';
import { ReactComponent as InstantBookingSvg } from 'assets/icons/instant_booking.svg';
import { ReactComponent as LikeSvg } from 'assets/icons/like.svg';
import { ReactComponent as DownloadSvg } from 'assets/icons/download.svg';
import { ReactComponent as BlueCheckSvg } from 'assets/icons/blue_check.svg';
import { ReactComponent as BusSvg } from 'assets/icons/bus.svg';
import { ReactComponent as PlaneSvg } from 'assets/icons/plane.svg';
import { ReactComponent as TrainSvg } from 'assets/icons/train.svg';

import { ReactComponent as DepartureArrowIconSvg } from 'assets/icons/departure_arrow.svg';
import { ReactComponent as ArriveArrowIconSvg } from 'assets/icons/arrive_arrow.svg';
import { ReactComponent as ArrDepIconSvg } from 'assets/icons/arr_dep_close.svg';
import { ReactComponent as Plus_20IconSvg } from 'assets/icons/plus_20.svg';
import { ReactComponent as MinusIconSvg } from 'assets/icons/minus.svg';
import { ReactComponent as PdfIconSvg } from 'assets/icons/pdf_icon.svg';
import { ReactComponent as InfoIconSvg } from 'assets/icons/icon_info.svg';

export const ArrDepIcon = styled(ArrDepIconSvg)`
  ${theme}
`;
export const ArriveArrowIcon = styled(ArriveArrowIconSvg)`
  ${theme}
`;
export const DepartureArrowIcon = styled(DepartureArrowIconSvg)`
  ${theme}
`;

export const LikeIcon = styled(LikeSvg)`
  ${theme}
`;
export const DownloadIcon = styled(DownloadSvg)`
  ${theme}
`;
export const AttentionIcon = styled(AttentionSvg)`
  ${theme}
`;
export const InstantBookingIcon = styled(InstantBookingSvg)`
  ${theme}
`;
export const Atm = styled(AtmSvg)`
  ${theme}
`;
export const Safe = styled(SafeSvg)`
  ${theme}
`;
export const BathRoom = styled(BathroomSvg)`
  ${theme}
`;
export const Wifi = styled(WifiSvg)`
  ${theme}
`;
export const BankCard = styled(BankCardSvg)`
  ${theme}
`;
export const Bar = styled(BarSvg)`
  ${theme}
`;
export const BathHat = styled(BathHatSvg)`
  ${theme}
`;
export const BeautySalon = styled(BeautySalonSvg)`
  ${theme}
`;
export const Conference = styled(ConferenceSvg)`
  ${theme}
`;
export const HealtyCentr = styled(HealtyCentrSvg)`
  ${theme}
`;
export const Nurse = styled(NurseSvg)`
  ${theme}
`;
export const OxygenCocktail = styled(OxygenCocktailSvg)`
  ${theme}
`;
export const Reception = styled(ReceptionSvg)`
  ${theme}
`;
export const Restaraunt = styled(RestarauntSvg)`
  ${theme}
`;
export const Sauna = styled(SaunaSvg)`
  ${theme}
`;
export const Spa = styled(SpaSvg)`
  ${theme}
`;
export const Transfer = styled(TransferSvg)`
  ${theme}
`;
export const TreePark = styled(TreeParkSvg)`
  ${theme}
`;
export const Gym = styled(GymSvg)`
  ${theme}
`;
export const Animator = styled(AnimatorSvg)`
  ${theme}
`;
export const Add = styled(AddSvg)`
  ${theme}
`;
export const Fitobar = styled(FitobarSvg)`
  ${theme}
`;
export const UsersIcon = styled(UsersIconSvg)`
  ${theme}
`;
export const RoomIcon = styled(RoomSvg)`
  ${theme}
`;
export const Heart = styled(HeartSvg)`
  ${theme}
`;
export const Budget = styled(BudgetSvg)`
  ${theme}
`;
export const Dropdown = styled(DropdownSvg)`
  ${theme}
`;
export const ArrowLeft = styled(ArrowLeftSvg)`
  ${theme}
`;
export const Calendar = styled(CalendarSvg)`
  ${theme}
`;
export const Check = styled(CheckSvg)`
  ${theme}
`;
export const CircleArrow = styled(CircleArrowSvg)`
  ${theme}
`;
export const Close = styled(CloseSvg)`
  ${theme}
`;
export const CloseSmall = styled(CloseSmallSvg)`
  ${theme}
`;
export const CornerDown = styled(CornerDownSvg)`
  ${theme}
`;
export const Delete = styled(DeleteSvg)`
  ${theme}
`;
export const Document = styled(DocumentSvg)`
  ${theme}
`;
export const Dots = styled(DotsSvg)`
  ${theme}
`;
export const Edit = styled(EditSvg)`
  ${theme}
`;
export const Error = styled(ErrorSvg)`
  ${theme}
`;
export const GoArrow = styled(GoArrowSvg)`
  ${theme}
`;
export const ImageError = styled(ImageErrorSvg)`
  ${theme}
`;
export const Location = styled(LocationSvg)`
  ${theme}
`;
export const Logo = styled(LogoSvg)`
  ${theme}
`;
export const Magnify = styled(MagnifySvg)`
  ${theme}
`;
export const Message = styled(MessageSvg)`
  ${theme}
`;
export const NoPhoto = styled(NoPhotoSvg)`
  ${theme}
`;
export const Pen = styled(PenSvg)`
  ${theme}
`;
export const Plus = styled(PlusSvg)`
  ${theme}
`;
export const Search = styled(SearchSvg)`
  ${theme}
`;
export const Sort = styled(SortSvg)`
  ${theme}
`;
export const Star = styled(StarSvg)`
  ${theme}
`;
export const StarBig = styled(StarBigSvg)`
  ${theme}
`;
export const Success = styled(SuccessSvg)`
  ${theme}
`;
export const SuccessSmall = styled(SuccessSmallSvg)`
  ${theme}
`;
export const Triangle = styled(TriangleSvg)`
  ${theme}
`;
export const WarnQuestion = styled(WarnQuestionSvg)`
  ${theme}
`;
export const NotAvailable = styled(NotAvailableSvg)`
  ${theme}
`;
export const BlueCopyIcon = styled(BlueCopyIconSvg)`
  ${theme}
`;
export const GreyCopyIcon = styled(GreyCopyIconSvg)`
  ${theme}
`;
export const UserBlueIcon = styled(UserBlueSvg)`
  ${theme}
`;
export const UserGreyIcon = styled(UserGreySvg)`
  ${theme}
`;
export const CommissionVisibleIcon = styled(CommissionVisibleSvg)`
  ${theme}
`;
export const CommissionHiddenIcon = styled(CommissionHiddenSvg)`
  ${theme}
`;
export const StopSalesIcon = styled(StopSalesSvg)`
  ${theme}
`;
export const BadgeChargeIcon = styled(BadgeChargeSvg)`
  ${theme}
`;
export const BadgeVoidIcon = styled(BadgeVoidSvg)`
  ${theme}
`;
export const BadgePayIcon = styled(BadgePaySvg)`
  ${theme}
`;
export const BadgeRefundIcon = styled(BadgeRefundSvg)`
  ${theme}
`;
export const InvoiceExpiredIcon = styled(InvoiceExpiredSvg)`
  ${theme}
`;
export const InvoicePaidIcon = styled(InvoicePaidSvg)`
  ${theme}
`;
export const InvoiceRequiredIcon = styled(InvoiceRequiredSvg)`
  ${theme}
`;
export const InvoiceTriggeredIcon = styled(InvoiceTriggeredSvg)`
  ${theme}
`;
export const UserBlockIcon = styled(UserBlockSvg)`
  ${theme}
`;
export const BedIcon = styled(BedSvg)`
  ${theme}
`;
export const QuestionIcon = styled(QuestionSvg)`
  ${theme}
`;
export const ProviderOstrovok = styled(ProviderOstrovokSvg)`
  ${theme}
`;
export const ProviderOstrovokV2 = styled(ProviderOstrovokV2Svg)`
  ${theme}
`;
export const BlueCheck = styled(BlueCheckSvg)`
  ${theme}
`;
export const BusIcon = styled(BusSvg)`
  ${theme}
`;
export const PlaneIcon = styled(PlaneSvg)`
  ${theme}
`;
export const TrainIcon = styled(TrainSvg)`
  ${theme}
`;
export const Plus_20Icon = styled(Plus_20IconSvg)`
  ${theme}
`;
export const MinusIcon = styled(MinusIconSvg)`
  ${theme}
`;
export const PdfIcon = styled(PdfIconSvg)`
  ${theme}
`;
export const InfoIcon = styled(InfoIconSvg)`
  ${theme}
`;
