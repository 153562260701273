import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import Qs from 'qs';

import { CustomRoute, Analitics } from 'components/common';

import {
  Auth,
  Finances,
  Orders,
  Reports,
  Settings,
  Statistics,
  Notifications,
  NotFound,
  Info,
  Resale,
  Contracts
} from 'pages';

@withRouter
@withCookies
@inject('authStore')
@observer
class App extends React.Component {
  static propTypes = {
    authStore: PropTypes.object.isRequired,
    cookies: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { cookies, location, history } = this.props;

    const { crm_id } = Qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!crm_id) {
      return null;
    }

    const options = {
      maxAge: 15 * 60, // 15 minutes
      path: '/',
      secure: process.env.NODE_ENV === 'production'
    };

    cookies.set('crm_id', crm_id, options);
    history.replace(location.pathname);
  }

  render() {
    const { authStore } = this.props;

    return (
      <>
        {authStore.isAuthenticated && <Notifications />}

        <Switch>
          <Redirect exact from='/' to='/orders' />

          <CustomRoute isPrivate path='/orders' component={Orders} />

          <CustomRoute isPrivate path='/reports' component={Reports} />

          <CustomRoute isPrivate path='/resale' component={Resale} />

          <CustomRoute isPrivate path='/statistics' component={Statistics} />

          <CustomRoute isPrivate path='/finances' component={Finances} />

          <CustomRoute isPrivate path='/settings' component={Settings} />

          <CustomRoute isPrivate path='/contracts' component={Contracts} />

          <CustomRoute path='/info' component={Info} />

          <Auth />

          <CustomRoute path='*' component={NotFound} />
        </Switch>

        <Analitics />
      </>
    );
  }
}

export default App;
