import { useEffect, useReducer } from 'react';

import instance from 'connection/instance';
import { reducer, initialState } from './reducer';
import { CONTRACT_ERROR, CONTRACT_LOADING, ERROR, GET_DATA, LOADING } from './constants';

const useCompany = () => {
  const [store, dispatch] = useReducer(reducer, initialState);

  const getData = (data) => dispatch({ type: GET_DATA, data });
  const setLoading = () => dispatch({ type: LOADING });
  const setError = (data) => dispatch({ type: ERROR, data });
  const setContractLoading = (data) => dispatch({ type: CONTRACT_LOADING });
  const setContractError = (data) => dispatch({ type: CONTRACT_ERROR, data });

  const updateInfo = async (values) => {
    try {
      setError(null);
      setLoading();

      await instance.patch('/api/organizations', { data: values });
    } catch (e) {
      setError(e?.response?.data?.messages?.[0]);
    } finally {
      setLoading();
    }
  };

  const downloadContract = async () => {
    try {
      setContractLoading(true);

      const resp = await instance.get('/api/organizations/download_contract', { responseType: 'blob' });

      const blob = new Blob([resp.data], {
        type: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'contract.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setContractError(e?.response?.data?.messages?.[0]);
    } finally {
      setContractLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const {
          data: { data }
        } = await instance.get('/api/organizations');

        getData(data);
      } catch (e) {
        console.log(e, 'err');
        setError(e?.response?.data?.messages?.[0]);
      }
    };

    fetch();
  }, []);

  return [{ ...store }, { updateInfo, setError, downloadContract, setContractError }];
};

export default useCompany;
