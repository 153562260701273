import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Logo } from 'components/icons';
import { Menu } from 'components/molecules';
import { CommissionButton, Balance, QuestionButton } from 'components/ui';
import { UserBar } from './components';

import { flexCenter } from 'theme/mixins';

const LogoWrapper = styled.div`
  ${flexCenter()};
  font-size: 38px;
`;

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 20px;
    height: 72px;
    background: ${theme.colors.active.secondary};
    color: ${theme.colors.light};
  `}
`;

@withRouter
class Header extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    location: ReactRouterPropTypes.location.isRequired,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    ),
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { location, t, ...rest } = this.props;

    let navigation = [
      {
        id: '1',
        isActive: location.pathname.match(/^\/orders/),
        title: t('Pages.Orders'),
        to: '/orders',
        visible: process.env.REACT_APP_V_ORDERS
      },

      {
        id: '2',
        isActive: location.pathname.match(/^\/resale/),
        title: t('Pages.Resale'),
        to: '/resale',
        visible: process.env.REACT_APP_V_ORDERS
      },
      {
        id: '3',
        isActive: location.pathname.match(/^\/reports/),
        title: t('Pages.Reports'),
        to: '/reports',
        visible: process.env.REACT_APP_V_REPORTS
      },
      {
        id: '4',
        isActive: location.pathname.match(/^\/finances/),
        title: t('Pages.Finances'),
        to: '/finances',
        visible: process.env.REACT_APP_V_FINANCE
      },
      {
        id: '5',
        isActive: location.pathname.match(/^\/statistics/),
        title: t('Pages.Statistics'),
        to: '/statistics',
        visible: process.env.REACT_APP_V_STATISTICS
      },
      {
        id: '6',
        isActive: location.pathname.match(/^\/сontracts/),
        title: t('Pages.Contracts'),
        to: '/contracts',
        visible: process.env.REACT_APP_V_CONTRACTS
      },
      {
        id: '7',
        isActive: location.pathname.match(/^\/settings/),
        title: t('Pages.Settings'),
        to: '/settings',
        visible: process.env.REACT_APP_V_SETTINGS
      }
    ];

    navigation = navigation.filter((item) => item.visible === 'true');

    return (
      <Wrapper {...rest}>
        <LogoWrapper>
          <NavLink to='/'>
            <Logo />
          </NavLink>
        </LogoWrapper>

        <Menu items={navigation} />

        <QuestionButton />

        <Balance />

        <CommissionButton />

        <UserBar />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Header))``;
