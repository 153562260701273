import React from 'react';
import PropTypes from 'prop-types';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';

import Body from './Body';

const Contracts = ({ className }) => {
  return <DefaultTemplate header={<Header />} body={<Body />} />;
};

Contracts.propTypes = {
  className: PropTypes.string
};

export default Contracts;
