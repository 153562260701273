import { CONTRACT_ERROR, CONTRACT_LOADING, ERROR, GET_DATA, LOADING } from './constants';

export const initialState = {
  data: null,
  error: null,
  loading: false,
  contractLoading: false,
  contractError: null
};

export const reducer = (state, action) => {
  switch (action.type) {
    case GET_DATA: {
      return { ...state, data: action.data };
    }

    case LOADING: {
      return { ...state, loading: !state.loading };
    }

    case ERROR: {
      return { ...state, error: action.data };
    }

    case CONTRACT_LOADING: {
      return { ...state, contractLoading: !state.contractLoading };
    }

    case CONTRACT_ERROR: {
      return { ...state, contractError: action.data };
    }

    default:
      return state;
  }
};
