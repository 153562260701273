import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BackLink, ErrorModal } from 'components/ui';
import Heading from 'pages/Auth/components/Heading';
import Form from 'components/v2/forms/Form';
import useCompanyInfo from './hooks/useCompanyInfo';
import { schema } from './schema';
import { defaultValues } from './formFieldsData';

import Content from './Content';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(86deg, #d3d9fc 0%, #f5e3ff 100%);
`;

const Back = styled(BackLink)`
  border-radius: 56px;
  padding: 8px 16px;
  flex: 0;
  background: #fff;
`;

const Title = styled(Heading)`
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  width: 1256px;
  margin: 55px auto 0;
`;

const Register = ({ className }) => {
  const [{ registerError }, { register, setRegisterError }] = useCompanyInfo();

  const handleCloseModal = () => {
    setRegisterError(false);
  };

  const onSubmit = async (data) => {
    await register(data);
  };

  return (
    <Wrapper className={className}>
      <Header>
        <Back to='/login' />

        <Title />
      </Header>

      <Form autoComplete='off' schema={schema} defaultValues={defaultValues} onSubmit={onSubmit}>
        <Content registerError={registerError} />
      </Form>

      <ErrorModal
        title='Произошла непредвиденная ошибка'
        description='Попробуйте еще раз или обратитесь в техподдержку'
        opened={registerError}
        onClose={handleCloseModal}
      />
    </Wrapper>
  );
};

Register.propTypes = {
  className: PropTypes.string
};

export default Register;
