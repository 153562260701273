import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Tabs } from 'components/molecules';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  padding: 36px 0 24px 0;

  ${Tabs} {
    margin-top: 42px;
  }
`;

const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.active.secondary};
    ${typography(40, 48, 700)};
  `}
`;

function Top({ className }) {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <Title>{t('Settings.Title')}</Title>

      <Tabs
        tabs={[
          {
            to: '/settings/users',
            label: t('Settings.Users.Title')
          },
          {
            to: '/settings/company',
            label: t('Settings.General.Company.Title')
          },
          // {
          //   to: '/settings/contacts',
          //   label: t('Settings.Contacts.Title')
          // },
          // {
          //   to: '/settings/contracts',
          //   label: t('Settings.Contracts.Title')
          // },
          {
            to: '/settings/commissions',
            label: t('Settings.General.Title')
          }
        ]}
      />
    </Wrapper>
  );
}

Top.propTypes = {
  className: PropTypes.string
};

export default styled(Top)``;
