import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import LegalEntityForm from '../LegalEntityForm';
import ManagerForm from '../ManagerForm';

const Wrapper = styled.div`
  display: flex;
  gap: 27px;
  max-width: 1256px;
  margin: 40px auto;
`;

const Content = ({ className, registerError }) => {
  const history = useHistory();
  const {
    formState: { isSubmitSuccessful },
    getValues
  } = useFormContext();

  useEffect(() => {
    if (isSubmitSuccessful && !registerError) {
      history.replace(`/success_register?email=${getValues()?.email}`);
    }
  }, [isSubmitSuccessful, registerError]);

  return (
    <Wrapper className={className}>
      <LegalEntityForm />

      <ManagerForm />
    </Wrapper>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  registerError: PropTypes.bool
};

export default Content;
