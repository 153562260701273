import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { DateTime } from 'luxon';

import { t } from 'utils/localization';
import { typography } from 'theme/mixins';
import { PdfIcon } from 'components/icons';
import useCompany from 'pages/Settings/containers/Company/hooks/useCompany';
import { ErrorModal } from 'components/ui';

const Content = styled.div`
  margin-top: 32px;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.2113);
  background: #fff;
  padding: 28px;
  border-radius: 4px;
`;

const Divider = styled.div`
  width: 100%;
  background: #e4e8ee;
  height: 1px;
  margin: 20px 0;
`;

const Bottom = styled.button`
  border: none;
  background: none;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  position: relative;
  cursor: pointer;
  width: fit-content;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const BottomInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BottomInfoTitle = styled.p`
  align-self: flex-start;
  color: #3c6f9d;
  ${typography(13, 17, 700)};
`;

const BottomInfoDescription = styled.p`
  color: #979ba0;
  ${typography(13, 17, 400)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 980px;
  margin: 0 auto;
  padding: 47px 10px 0;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.active.secondary};
    ${typography(40, 48, 700)};
  `}
`;

const Description = styled.p`
  color: #6b707b;
  ${typography(13, 17, 400)};
`;

const Icon = styled(PdfIcon)`
  font-size: 28px;
`;

function Body({ className }) {
  const [{ data, contractLoading, contractError }, { downloadContract, setContractError }] = useCompany();

  const activeContract = data?.contracts?.filter((contract) => contract?.active)?.[0];
  const activateDate = DateTime.fromJSDate(new Date(activeContract?.contract_at)).toFormat('dd.MM.yyyy');

  const handleCloseModal = () => {
    setContractError(null);
  };

  return (
    <Wrapper className={className}>
      <Title>{t('Contracts.Title')}</Title>

      <Content>
        <Description dangerouslySetInnerHTML={{ __html: t('Contracts.Description') }} />

        <Divider />

        <Bottom disabled={contractLoading} onClick={downloadContract}>
          <Icon />

          <BottomInfo>
            <BottomInfoTitle>Агентский договор</BottomInfoTitle>
            {activeContract && (
              <BottomInfoDescription>
                №{activeContract?.number} от {activateDate} г.
              </BottomInfoDescription>
            )}
          </BottomInfo>
        </Bottom>

        <ErrorModal
          title='Произошла непредвиденная ошибка'
          description='Попробуйте еще раз'
          opened={!!contractError}
          onClose={handleCloseModal}
        />
      </Content>
    </Wrapper>
  );
}

Body.propTypes = {
  className: PropTypes.string
};

export default styled(Body)``;
