import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import Top from './Top';
import Users from '../Users';
// import Contacts from '../Contacts';
// import Contracts from '../Contracts';
import Commissions from '../Commissions';
import Company from '../Company';

const Content = styled.div`
  padding-bottom: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 980px;
  margin: 0 auto;
  padding: 0 10px;
`;

function Body({ className }) {
  return (
    <Wrapper className={className}>
      <Top />

      <Content>
        <Switch>
          <Redirect exact from='/settings' to='/settings/users' />

          <Route path='/settings/users' component={Users} />

          {/*<Route exact path='/settings/contacts' component={Contacts} />*/}

          {/*<Route exact path='/settings/contracts' component={Contracts} />*/}

          <Route exact path='/settings/commissions' component={Commissions} />

          <Route exact path='/settings/company' component={Company} />
        </Switch>
      </Content>
    </Wrapper>
  );
}

Body.propTypes = {
  className: PropTypes.string
};

export default styled(Body)``;
